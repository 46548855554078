var render = function render(_c, _vm) {
  return _c(
    "div",
    {
      class: [
        "flex w-full px-1 pt-1",
        {
          "rounded border border-red-400 bg-red-200": _vm.props.error,
          "flex-col": _vm.props.column,
          "flex-row": !_vm.props.column,
        },
        _vm.data.class,
        _vm.data.staticClass,
      ],
      style: [_vm.data.style, _vm.data.staticStyle],
    },
    [_vm._t("default")],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }