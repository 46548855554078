<template functional>
  <div
    :class="[
      'flex w-full px-1 pt-1',
      {
        'rounded border border-red-400 bg-red-200': props.error,
        'flex-col': props.column,
        'flex-row': !props.column,
      },
      data.class,
      data.staticClass,
    ]"
    :style="[data.style, data.staticStyle]"
  >
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: 'AsterixRadioGroup',
  props: {
    column: { type: Boolean, default: false },
    error: { type: Boolean, default: false },
  },
};
</script>
