<template>
  <label
    class="flex cursor-pointer mb-3"
    :class="{ 'cursor-not-allowed': disabled, 'hover:text-orange-400': !disabled }"
  >
    <span class="radio-container">
      <span class="radio">
        <span class="marker" :class="status"></span>
      </span>
    </span>
    <input
      class="hidden"
      :checked="checked"
      type="radio"
      :name="name"
      :value="inputValue"
      :required="required"
      @change="emitChange"
    />
    <slot name="label"></slot>
  </label>
</template>

<script>
export default {
  name: 'AsterixRadioInput',
  props: {
    required: {
      type: Boolean,
      default: false,
    },
    name: {
      type: String,
      default: () => null,
    },
    inputValue: {
      type: String,
      default: () => null,
    },
    value: {
      type: String,
      default: () => null,
    },
    disabled: {
      type: Boolean,
      default: () => false,
    },
  },
  computed: {
    checked() {
      return this.inputValue === this.value;
    },
    status() {
      return this.checked ? 'checked' : 'unchecked';
    },
  },
  methods: {
    emitChange() {
      if (!this.disabled) {
        const newVal = this.checked ? null : this.inputValue;
        this.$emit('input', newVal);
        this.$emit('change', newVal);
      }
    },
  },
};
</script>

<style scoped>
.radio-container {
  @apply mr-2;
  width: 16px;
  height: 16px;
}

.radio {
  @apply flex border border-orange-400 rounded-full;
  width: 16px;
  height: 16px;
}

.radio .marker.checked {
  @apply bg-orange-400 block rounded-full;
  width: 10px;
  height: 10px;
  margin: 2px;
}

.cursor-not-allowed .radio .marker.checked {
  @apply bg-gray-400;
}

.cursor-not-allowed .radio {
  @apply border-gray-400;
}
</style>
