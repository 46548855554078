var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "label",
    {
      staticClass: "flex cursor-pointer mb-3",
      class: {
        "cursor-not-allowed": _vm.disabled,
        "hover:text-orange-400": !_vm.disabled,
      },
    },
    [
      _c("span", { staticClass: "radio-container" }, [
        _c("span", { staticClass: "radio" }, [
          _c("span", { staticClass: "marker", class: _vm.status }),
        ]),
      ]),
      _c("input", {
        staticClass: "hidden",
        attrs: { type: "radio", name: _vm.name, required: _vm.required },
        domProps: { checked: _vm.checked, value: _vm.inputValue },
        on: { change: _vm.emitChange },
      }),
      _vm._t("label"),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }